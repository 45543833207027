






















































import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { RewardViewModel } from '../../viewmodel/reward-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject() vm!: RewardViewModel
  copy() {
    if (this.vm.yourReferralLink) {
      navigator.clipboard.writeText(this.vm.yourReferralLink)
      snackController.success('Copy successfully')
    }
  }
}
